import * as React from "react";
import Table from "@amzn/awsui-components-react/polaris/table";
import Box from "@amzn/awsui-components-react/polaris/box";
import Button from "@amzn/awsui-components-react/polaris/button";
import Header from "@amzn/awsui-components-react/polaris/header";
import { CommitMetadata, Feature, PipelineMetadata } from '../api/generated-src';
import { AppLayout, AttributeEditor, Container, Flashbar, Input, Modal, Multiselect, Pagination, Select, SpaceBetween, Spinner } from "@amzn/awsui-components-react";
import { Flag } from "./Flag";
import { useFlashbarItems, useSorting } from "./hooks/hooks";
import FeatureTrackerApi from '../api/FeatureTrackerApi';
import EditCommitsTable from "./EditCommitsTable";
import EditPipelinesTable from "./EditPipelinesTable";

const api = FeatureTrackerApi();

interface FeatureEditorProps {
  metadata?: Feature;
  loading: boolean;
  triggerDashboardRefresh: any;
  updateFeature: any;
}

export interface CommitItem {
  id: string;
  package: string;
  type: string;
  flagged: boolean;
}

export default (props: FeatureEditorProps) => {
  const [ commitItems, setCommitItems ] = React.useState<CommitMetadata[]>([]);
  const [ pipelineItems, setPipelineItems ] = React.useState<PipelineMetadata[]>([]);


  React.useEffect(() => {
    if (!props.metadata?.commits || !props.metadata?.pipelines) return;
    
    setCommitItems(props.metadata?.commits);
    setPipelineItems(props.metadata?.pipelines);
  }, [props.metadata]);

  const updateFeatureForPipelineTable = async (pipelines: PipelineMetadata[]): Promise<boolean> => {
    return await props.updateFeature(props.metadata?.name, { pipelines: pipelines, commits: commitItems }, true);
  };

  const updateFeatureForCommitTable = async (commits: CommitMetadata[], triggerDashboardUpdate: boolean): Promise<boolean> => {
    return await props.updateFeature(props.metadata?.name, { pipelines: pipelineItems, commits: commits }, triggerDashboardUpdate);
  };

  return (
    <SpaceBetween direction="vertical" size='m'>
      <EditPipelinesTable
        pipelineItems={pipelineItems}
        setPipelineItems={setPipelineItems}
        loading={props.loading}
        updateFeature={updateFeatureForPipelineTable}
      />
      <EditCommitsTable 
        commitItems={commitItems} 
        setCommitItems={setCommitItems}
        loading={props.loading}
        updateFeature={updateFeatureForCommitTable}
      />
    </SpaceBetween>
  );
}