import React from 'react';
import { Box, Button, Header, Link, Pagination, StatusIndicator, Table, TextContent } from '@amzn/awsui-components-react';
import { useSorting } from './hooks/hooks';
import { Flag } from './Flag';

export interface PipelineCommitTableProps {
  commits: Commit[];
  lastStage?: string;
  pipelineName: string;
  viewAllItemsFunctions: any;
  triggerFeatureUpdate: any;
}

export interface Commit {
  stage: string;
  type: string;
  id: string;
  package: string;
  flagged: boolean;
}

export interface CommitItem {
  id: string;
  status: string;
  statusText: string;
  stage: string;
  package: string;
  type: string;
  flagged: boolean;
}

export default (props: PipelineCommitTableProps) => {
  const [commitsItems, setCommitsItems] = React.useState<CommitItem[]>([]);
  const [ sortingColumn, isDescending, onSortingChange ] = useSorting(commitsItems, setCommitsItems);
  const [ currentPage, setCurrentPage ] = React.useState(1);

  const PAGE_SIZE = 10;
  const TABLE_NAME = `${props.pipelineName} commits`;

  React.useEffect(() => {
    if (!props.commits) return;

    const items = props.commits.map(commit => {
      return {
        id: commit.id,
        status: commit.stage === props.lastStage ? 'success' : 'pending',
        statusText: commit.stage === props.lastStage ? 'Fully deployed' : 'In-flight',
        stage: commit.stage,
        package: commit.package,
        type: commit.type,
        flagged: commit.flagged
      }
    });
    
    setCommitsItems(items);
  }, [props.commits]);

  const callback = (item: CommitItem) => {
    props.triggerFeatureUpdate({ id: item.id, flagged: item.flagged, package: item.package, type: item.type });
  }

  const commitsDefinition = [
    {
      id: 'flagged',
      header: 'Flagged',
      cell: item => <Flag item={item} callback={callback}/>,
      width: 110,
      sortingField: 'flagged'
    },
    {
      id: 'id',
      header: 'Commit ID',
      cell: item => <Link href={`https://code.amazon.com/packages/${item.package}/commits/${item.id}`}>{item.id}</Link>,
    },
    {
      id: 'status',
      header: 'Commit status',
      cell: ({ statusText, status }) => <StatusIndicator type={status}>{statusText}</StatusIndicator>,
      sortingField: 'status'
    },
    {
      id: 'stage',
      header: 'Stage',
      cell: item => item.stage,
      sortingField: 'stage'
    }, 
    {
      id: 'name',
      header: 'Package name',
      cell: item => item.package,
      minWidth: 250,
      sortingField: 'package'
    },
    {
      id: 'type',
      header: 'Commit type',
      cell: item => item.type === 'source' ? 'Source commit' : 'Testing commit',
      sortingField: 'type'
    },
  ];


  const populateSplitPanel = () => {
    props.viewAllItemsFunctions.setItems(commitsItems);
    props.viewAllItemsFunctions.setColumnDefinition(commitsDefinition);
    props.viewAllItemsFunctions.setName(TABLE_NAME);
    props.viewAllItemsFunctions.triggerSplitPanel(true);
  };

  return (
    <Table
      resizableColumns={true}
      header={
        <Header 
          description={`These are the commits in this feature that appear in the ${props.pipelineName} pipeline.`}
          counter={`(${commitsItems.length})`}>{TABLE_NAME}
        </Header>
      }
      items={commitsItems.slice((currentPage - 1) * PAGE_SIZE, currentPage * PAGE_SIZE)}
      columnDefinitions={commitsDefinition}
      empty={
        <Box textAlign="center" color="inherit">
          <b>No resources</b>
          <Box padding={{ bottom: "s" }} variant="p" color="inherit"          >
            No resources to display.
          </Box>
        </Box>
      }
      // footer={
      //   commitsItems.length > PAGE_SIZE && <Box textAlign="center">
      //     <Button onClick={() => populateSplitPanel()}>View all</Button>
      //   </Box>
      // }
      pagination={Math.ceil(commitsItems.length / PAGE_SIZE) > 1 &&
        <Pagination
          currentPageIndex={currentPage}
          pagesCount={Math.ceil(commitsItems.length / PAGE_SIZE)}
          onChange={({ detail }) => setCurrentPage(detail.currentPageIndex)}
        />
      }
      sortingColumn={sortingColumn}
      sortingDescending={isDescending}
      onSortingChange={(event) => onSortingChange(event)}
    />
  );
}