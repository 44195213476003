// Earliest commit location per pipeline
// All stages and commits for each pipeline
// Location of flagged commits
// Overview: Num pipelines, num commits, percent launched
// Feature deployment completion over time
// Commits location bar chart
import AppLayout from '@amzn/awsui-components-react/polaris/app-layout';
import Grid from '@amzn/awsui-components-react/polaris/grid';
import Spinner from '@amzn/awsui-components-react/polaris/spinner';
import './styles.scss';
import CommitTable, { Commit } from './PipelineCommitTable';
import OverviewWidget from './OverviewWidget';
import EarliestCommitTable from './EarliestCommitTable';
import ViewAllItemsTable from './ViewAllItemsTable';
import React from 'react';

const getNumInFlightCommits = (pipelines) => {
  if (pipelines.length == 0) return '-';

  const inFlightCommits = {};
  let numInFlight = 0;
  let numSeenCommits = 0;
  pipelines.forEach((pipeline) => {
    for (const commit of pipeline.commits) {
      numSeenCommits += 1;
      const commitId = commit.id;
      if (commit.stage !== pipeline.lastStage) {
        if (!inFlightCommits[commitId]) {
          inFlightCommits[commitId] = 'In flight';
          numInFlight++;
        }
      }
    }
  });
  return numInFlight;
};

export interface DashboardProps {
  loading: boolean;
  pipelines?: Pipeline[];
  overview?: Overview;
  triggerFeatureUpdate: any;
}

interface Overview {
  numCommits: number;
  numPipelines: number;
}

export interface Pipeline {
  name: string;
  lastStage?: string;
  earliestCommitStage?: string;
  commits: Commit[];
}

export default function Dashboard(props: DashboardProps) {
  const [ splitPanelTriggered, triggerSplitPanel ] = React.useState(false);
  const [ viewAllItemsTableItems, setViewAllItemsTableItems ] = React.useState<any[]>();
  const [ viewAllItemsTableColumnDefinition, setViewAllItemsTableColumnDefinition ] = React.useState();
  const [ viewAllItemsTableName, setViewAllItemsTableName ] = React.useState();

  const triggerAndOpenSplitPanel = (action) => {
    triggerSplitPanel(action);
    setSplitPanelOpen(action);
  };

  const viewAllItemsFunctions = { setItems: setViewAllItemsTableItems, setColumnDefinition: setViewAllItemsTableColumnDefinition, setName: setViewAllItemsTableName, triggerSplitPanel: triggerAndOpenSplitPanel };

  const [ splitPanelOpen, setSplitPanelOpen] = React.useState(false);

  const pipelineSorter = (a: Pipeline, b: Pipeline) => {
    if (a.commits.length > b.commits.length) return 1;
    if (b.commits.length > a.commits.length) return -1;
    return 0;
  }

  const pipelines = props.pipelines?.sort(pipelineSorter) || [];
  const loading  = props.loading;
  const numPipelines = props.overview?.numPipelines || '-';
  const numCommits = props.overview?.numCommits || '-';
  const gridDefinition = [
    { colspan: { l: 7, m: 7, default: 12 } },
    { colspan: { l: 5, m: 5, default: 12 } },
  ];

  pipelines.forEach(() => {
    gridDefinition.push({ colspan: { l: 12, m: 12, default: 12 }});
  })
  const numInFlightCommits = getNumInFlightCommits(pipelines);
  const numFullyDeployed = numCommits === '-' || numPipelines === '-' || numInFlightCommits === '-' ? '-' : numCommits - numInFlightCommits;

  const onSplitPanelToggle = (detail) => {
    setSplitPanelOpen(detail.open);
  };

  return (
    <AppLayout
      splitPanelOpen={splitPanelOpen}
      onSplitPanelToggle={({ detail }) => onSplitPanelToggle(detail)}
      splitPanel={
        splitPanelTriggered ? 
        (<ViewAllItemsTable 
          items={viewAllItemsTableItems} 
          columnDefinition={viewAllItemsTableColumnDefinition}  
          name={viewAllItemsTableName} 
        />) : undefined
      }
      content={
        <div>
          {loading && <Spinner />}
          <Grid gridDefinition={gridDefinition}>
            {!loading && <OverviewWidget numCommits={numCommits} numPipelines={numPipelines} numInFlight={numInFlightCommits} numFullyDeployed={numFullyDeployed} />}
            {!loading && <EarliestCommitTable items={pipelines} viewAllItemsFunctions={viewAllItemsFunctions} />}
            {!loading &&
              pipelines.map((pipeline) => {
                return <CommitTable pipelineName={pipeline.name} commits={pipeline.commits} lastStage={pipeline.lastStage} viewAllItemsFunctions={viewAllItemsFunctions} triggerFeatureUpdate={props.triggerFeatureUpdate} />;
              })}
          </Grid>
          </div>}
      contentType="default"
      navigationHide={true}
      toolsHide={true}
      disableContentPaddings={true}
    />
  );
}