import { Route, Switch } from 'react-router-dom';
import Homepage from './Homepage';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import ViewFeature from './ViewFeature';
export default function App() {
  return (
    <div>
      <SpaceBetween direction='vertical' size='s'>
        <Switch>
          <Route exact path="/" component={Homepage} />
          <Route path='/features/:featureName' component={ViewFeature}/>
        </Switch>  
      </SpaceBetween>
    </div>
  );
}