import { Box, Button, Header, Input, Modal, Pagination, Select, SpaceBetween, Table } from "@amzn/awsui-components-react";
import React from "react";
import { PipelineMetadata } from "../api/generated-src";
import PipelineCommitTable from "./PipelineCommitTable";
import { useSorting } from "./hooks/hooks";

interface PipelineTableProps {
  pipelineItems: PipelineMetadata[];
  setPipelineItems: React.Dispatch<React.SetStateAction<PipelineMetadata[]>>;
  loading: boolean;
  updateFeature: any;
}

export default (props: PipelineTableProps) => {
  const [ currentPage, setCurrentPage ] = React.useState(1);
  const [ selectedItems, setSelectedItems ] = React.useState<PipelineMetadata[]>([]);
  const [ isModalVisible, setIsModalVisible ] = React.useState<boolean>(false);
  const [ isPipelineNameInvalid, setIsPipelineNameInvalid ] = React.useState(false);
  const [ newPipeline, setNewPipeline ] = React.useState<Partial<PipelineMetadata>>();
  const [ sortingColumn, isDescending, onSortingChange ] = useSorting(props.pipelineItems, props.setPipelineItems);

  const PAGE_SIZE = 10;

  const addPipeline = async () => {
    if (newPipeline?.name === undefined || newPipeline.name === "" || props.pipelineItems.map(item => item.name).includes(newPipeline.name)) {
      setIsPipelineNameInvalid(true);
    } else {
      if (!props.pipelineItems) return;
      props.pipelineItems.push({ name: newPipeline.name});
      const success = await props.updateFeature(props.pipelineItems);

      if (success) {
        props.setPipelineItems(props.pipelineItems);
      }
      setNewPipeline({});
      setIsModalVisible(false);
    } 
  };

  const removePipeline = async () => {
    const newPipelineItems = props.pipelineItems.filter(item => item.name != selectedItems[0].name);
    const success = await props.updateFeature(newPipelineItems);
    if (success) {
      props.setPipelineItems(newPipelineItems);
    }
    setSelectedItems([]);
  };

  return (          
    <Table
      columnDefinitions={[{
        id: 'name',
        header: 'name',
        cell: item => item.name,
        sortingField: 'name'
      }]}
      selectedItems={selectedItems}
      selectionType="single"
      onSelectionChange={(event:any) => {setSelectedItems(event.detail.selectedItems)}}
      items={props.pipelineItems.slice((currentPage - 1) * PAGE_SIZE, currentPage * PAGE_SIZE)}
      loadingText="Loading resources"
      empty={
        <Box textAlign="center" color="inherit">
          <b>No resources</b>
          <Box
            padding={{ bottom: "s" }}
            variant="p"
            color="inherit"
          >
            No resources to display.
          </Box>
        </Box>
      }
      pagination={
        <Pagination
          currentPageIndex={currentPage}
          pagesCount={Math.ceil(props.pipelineItems.length / PAGE_SIZE)}
          onChange={({ detail }) => setCurrentPage(detail.currentPageIndex)}
        />
      }
      header={
        <Header description='These are the pipelines in the feature.' counter={`(${props.pipelineItems.length})`} actions={
          <SpaceBetween size='xs' direction='horizontal'>
            <Button disabled={props.loading} onClick={() => setIsModalVisible(true)}>Add</Button>
            <Modal
              visible={isModalVisible}
              onDismiss={() => setIsModalVisible(false)}
              header={'Add commit'}
              footer={
                <Box float="right">
                  <Button variant='primary' onClick={() => addPipeline()}>Add</Button>
                </Box>
              }
            >
              <SpaceBetween size="s" direction="vertical">
                <Input invalid={isPipelineNameInvalid} placeholder="Pipeline name" value={newPipeline?.name || ""} onChange={(event) => {setIsPipelineNameInvalid(false); setNewPipeline({ ...newPipeline, name: event.detail.value })}}></Input>
              </SpaceBetween>
            </Modal>
            <Button disabled={selectedItems.length === 0 || props.loading} onClick={() => removePipeline()}>Remove</Button>
          </SpaceBetween>
        }>Pipelines</Header>
        }
        loading={props.loading}
        sortingColumn={sortingColumn}
        sortingDescending={isDescending}
        onSortingChange={(event) => onSortingChange(event)}
    />);
}