import React from 'react';
import { Box, Container, Header, ColumnLayout } from '@amzn/awsui-components-react';

const CounterLink = ({ children }) => {
  return (<Box fontSize='display-l' fontWeight='light'>
    {children}
  </Box>)
}

export default function OverviewWidget(props) {
  const numPipelines = props.numPipelines;
  const numCommits = props.numCommits;
  const numFullyDeployed = props.numFullyDeployed;
  const numInFlight = props.numInFlight;

  return (
    <Container
      header={
        <Header variant="h2" description="">
          Feature overview
        </Header>
      }
    >
      <ColumnLayout columns={4} variant="text-grid">
        <div>
          <Box variant="awsui-key-label">Pipelines</Box>
          <CounterLink>{numPipelines}</CounterLink>
        </div>
        <div>
          <Box variant="awsui-key-label">Commits</Box>
          <CounterLink>{numCommits}</CounterLink>
        </div>
        <div>
          <Box variant="awsui-key-label">Fully deployed</Box>
          <CounterLink>{numFullyDeployed}</CounterLink>
        </div>
        <div>
          <Box variant="awsui-key-label">In flight</Box>
          <CounterLink>{numInFlight}</CounterLink>
        </div>
      </ColumnLayout>
    </Container>
  );
}