import { SpaceBetween } from '@amzn/awsui-components-react';
import Box from '@amzn/awsui-components-react/polaris/box';
import Grid from '@amzn/awsui-components-react/polaris/grid';
import Link from '@amzn/awsui-components-react/polaris/link';
import * as tokens from '@amzn/awsui-design-tokens';

export default function CommonHeader() {
  return (
    <div style={{ background: tokens.colorBackgroundHomeHeader }}>
      <Box padding={{ vertical: 'xxl', horizontal: 's' }}>
        <Grid
          gridDefinition={[
            { offset: { l: 2, xxs: 1 }, colspan: { l: 8, xxs: 10 } }
          ]}
        >
          <div className="custom-home-text-inverted">
            <Box variant="h1" fontWeight="bold" padding="n" fontSize="display-l" color="inherit">
              Feature Tracker
            </Box>
            <Box fontWeight="light" padding={{ bottom: 's' }} fontSize="display-l" color="inherit">
              Track dependencies between multiple pipelines
            </Box>
            <SpaceBetween direction='horizontal' size='xs'>
              <Box fontWeight="light" padding={{ bottom: 's' }} fontSize="heading-s" color="inherit">
                Found a bug? Have a suggestion? Contact us here:
              </Box>
              <Link 
                external 
                href='https://issues.amazon.com/issues/create?assignedFolder=5be2bf71-5426-445e-b450-566d5f6625db'
              >
                Feature Tracker SIM Folder
              </Link>
            </SpaceBetween>
          </div>
        </Grid>
      </Box>
    </div>
  );
}