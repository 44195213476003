import React from 'react';
import { Box, Header, Link, Icon, Table, SpaceBetween, Pagination, Button } from '@amzn/awsui-components-react';
import { useCollection } from '@amzn/awsui-collection-hooks';

import { Pipeline } from './Dashboard';
import { useSorting } from './hooks/hooks';

interface EarliestCommitTableProps {
  items: Pipeline[];
  viewAllItemsFunctions: any;
}

export default (props: EarliestCommitTableProps) => {
  const PAGE_SIZE = 5;
  const TABLE_NAME = 'Earliest commit locations';
  const CURRENT_PAGE = 1;

  const [ items, setItems ] = React.useState(props.items);
  const [ sortingColumn, isDescending, onSortingChange ] = useSorting(items, setItems);

  const columnDefinition = [
    {
      id: 'pipelines',
      header: 'Pipeline name',
      cell: item => item.pipelineName,
      minWidth: 350,
      width: 350,
      sortingField: 'pipelineName'
    },
    {
      id: 'earliesCommit',
      header: 'Stage',
      cell: ({ iconName, stage }) => 
      (
        <SpaceBetween direction='horizontal' size='xs'>
          <Box>{stage}</Box>
          {stage != '-' && <Icon name={iconName} variant={iconName === 'status-positive' ? 'success' : 'subtle'}></Icon>}
        </SpaceBetween>
      ),
      minWidth: 150,
      width: 130,
      sortingField: 'stage'
    }
  ];

  const itemMapping = (items) => {
    return items.map((item) => {
      return {
        pipelineName: item.name,
        stage: item.earliestCommitStage || '-',
        iconName: item.earliestCommitStage === item.lastStage ? 'status-positive' : (item.earliestCommitStage === '-' ? '' : 'status-pending'),
      }
    });
  };
  
  React.useEffect(() => {
    setItems(itemMapping(props.items));
  }, [])

  const populateSplitPanel = () => {
    props.viewAllItemsFunctions.setItems(items);
    props.viewAllItemsFunctions.setColumnDefinition(columnDefinition);
    props.viewAllItemsFunctions.setName(TABLE_NAME);
    props.viewAllItemsFunctions.triggerSplitPanel(true);
  };

  return (
    <Table
      resizableColumns={true}
      header={
        <Header 
          counter={`(${items.length})`}
          description='This table contains the earliest location (i.e. stage) in each pipeline in the feature that contains a commit in the feature.'
        >
            {TABLE_NAME}
        </Header>
      }
      items={items.slice((CURRENT_PAGE - 1) * PAGE_SIZE, CURRENT_PAGE * PAGE_SIZE)}
      columnDefinitions={columnDefinition}
      empty={
        <Box textAlign="center" color="inherit">
          <b>No resources</b>
          <Box padding={{ bottom: "s" }} variant="p" color="inherit"          >
            No resources to display.
          </Box>
        </Box>
      }
      footer={
        items.length > PAGE_SIZE && <Box textAlign="center">
          <Button onClick={() => populateSplitPanel()}>View all</Button>
        </Box>
      }
      sortingColumn={sortingColumn}
      sortingDescending={isDescending}
      onSortingChange={(event) => onSortingChange(event)}
    />
  );
}