import React from 'react';
import AppLayout from '@amzn/awsui-components-react/polaris/app-layout';
import Tabs from '@amzn/awsui-components-react/polaris/tabs';
import Toggle from '@amzn/awsui-components-react/polaris/toggle';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Dashboard, { DashboardProps } from './Dashboard';
import './styles.scss';
import FeatureTrackerApi from '../api/FeatureTrackerApi';
import { CommitMetadata, Feature, FeatureStatus, FeatureUpdate, Pipeline } from '../api/generated-src';
import  { Commit } from './PipelineCommitTable';
import CommonHeader from './CommonHeader';
import { Flashbar } from '@amzn/awsui-components-react';
import { useFlashbarItems, useSorting } from './hooks/hooks';
import FeatureEditor from './FeatureEditor';

const api = FeatureTrackerApi();

export default function ViewFeature(props) {
  const [loading, setLoading] = React.useState(false);
  const [loadingJustDashboard, setLoadingJustDashboard] = React.useState(false);
  const [pipelines, setPipelines] = React.useState([]);
  const [dashboardProps, setDashboardProps] = React.useState<{  overview: { numPipelines: number; numCommits: number; }; pipelines: { name: string; lastStage: string | undefined; earliestCommitStage: string | undefined; commits: Commit[] }[]; }>();
  const [checked, setChecked] = React.useState(true);

  const [featureMetadata, setFeatureMetadata] = React.useState<Feature>();
  const [updatedFeatureMetadata, setUpdatedFeatureMetadata] = React.useState<FeatureUpdate>({});
  const { flashbarItems, addFlashbarItem, FLASHBAR_TYPES } = useFlashbarItems();
  const isMounted = React.useRef(false);
  
  const featureName = props.match?.params?.featureName;
  /*
  * name: Name of the pipeline
  * stages: Array of pipeline stages. Includes name, array of targets, and array of commits.
  * targetsMap: Object that maps targest to stages.
  * lastStage: The last stage in the pipeline.
  * stagesOrder:  The order of stages in the pipeline.
  * commits:  Commits in the pipeline (with corresponding pipeline stage).
  */
  const sanitize = (target) => {
    const dividers = [':BATS:', ':VS:', ':CF:', ':DG:'];

    for (const divider of dividers) {
      if (target.includes(divider)) {
        return target.split(divider)[0];
      }
    }

    throw new Error('Invalid target with unknown divider: ' + target);
  }

  const constructDashboardProps = (featureStatus: FeatureStatus) => {
    const numPipelines = featureStatus.pipelines?.length;
    const commits = new Set();

    featureStatus.pipelines?.forEach(pipeline => {
      const packages = new Set(pipeline.stages[0].targets.map(target => target.name.split('/')[0]));
      pipeline.stages?.forEach(stage => {
        if (stage.commits) {
          if (checked) {
            stage.commits.filter(commit => packages.has(commit.package)).map(commit => commit.id).forEach(id => commits.add(id));
          } else {
            stage.commits.map(commit => commit.id).forEach(id => commits.add(id));
          }
        }
      });
    });

    console.log(commits);

    const overview = { numPipelines, numCommits: commits.size };

    const pipelines = featureStatus.pipelines?.map(pipeline => {
      let lastStageName: string | undefined;
      let earliestCommitStage: string | undefined;
      const packages = new Set(pipeline.stages[0].targets.map(target => target.name.split('/')[0]));
      let commits: Commit[] = [];

      console.log(pipeline);
      if (pipeline.stages) {
        lastStageName = pipeline.stages[pipeline.stages.length - 1].name;
        for (const stage of pipeline.stages) {
          if (stage.commits.length > 0 && !earliestCommitStage) {
            
            if (checked) {
              let containsPipelineSpecificCommits;
              stage.commits.forEach(commit => {
                if (packages.has(commit.package)) {
                  containsPipelineSpecificCommits = true;
                }
              });

              if (!containsPipelineSpecificCommits) continue;
            }

            earliestCommitStage = stage.name;
          }

          const commitsForStage = stage.commits.map(commit => ({...commit, stage: stage.name  }));

          commits.push(...commitsForStage);
        }
      }

      // Show pipeline-specific changes.
      if (checked) {
        commits = commits.filter(commit => packages.has(commit.package));
      }
      
      if (commits.length == 0) {
        earliestCommitStage = undefined;
      }

      return { name: pipeline.name, lastStage: lastStageName, earliestCommitStage, commits, packages  };
    });

    return { overview, pipelines, showPipelineSpecificChanges: checked };
  };

  const addErrorFlashbar = (message: string) => {
    addFlashbarItem({ type: FLASHBAR_TYPES.ERROR, content: message })
  };

  React.useEffect(() => {
    (async () => {
      setLoading(true);
      setLoadingJustDashboard(true);

      try {
        const featureMetadata = await api.getFeature(featureName);
        setFeatureMetadata(featureMetadata.data);
        setLoading(false);

        const featureStatus = await api.getFeatureStatus(featureName);
        console.log(featureStatus);
        setDashboardProps(constructDashboardProps(featureStatus.data));
        setLoadingJustDashboard(false);
      } catch (error: any) {
        console.error(error);
        addErrorFlashbar(error.response.data.message);
        setLoading(false);
        setLoadingJustDashboard(false);
      }
    })();
  }, [checked]);
  
  const updateFeature = async (name: string, update: FeatureUpdate, shouldTriggerDashboardRefresh: boolean): Promise<boolean> => {
    try {
      await api.updateFeature(name, update);
      setFeatureMetadata({ name: featureName, pipelines: update.pipelines, commits: update.commits });

      if (shouldTriggerDashboardRefresh) {
        triggerDashboardRefresh();
      }

      return true;
    } catch (error: any) {
      addErrorFlashbar(error.response.data.message);
      return false;
    }
  };

  const triggerFeatureUpdate = async (commit: CommitMetadata, triggerDashboardRefresh: boolean) => {
    console.log('calling trigger feature update', commit, featureMetadata);
    const commitToUpdate = featureMetadata?.commits?.find((tempCommit) => tempCommit.id === commit.id);
    if (!commitToUpdate) {
      return;
    }
    commitToUpdate.flagged = commit.flagged;
    return await updateFeature(featureMetadata?.name || "", { commits: featureMetadata?.commits, pipelines: featureMetadata?.pipelines }, triggerDashboardRefresh);
  }

  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width: width - 250,
      height: height - 250,
    };
  }

  const triggerDashboardRefresh = async () => {
    setLoadingJustDashboard(true);
    try {
      const featureMetadata = await api.getFeature(featureName);
      setFeatureMetadata(featureMetadata.data);

      const featureStatus = await api.getFeatureStatus(featureName);
      setDashboardProps(constructDashboardProps(featureStatus.data));

    } catch (error: any) {
      addErrorFlashbar(error.response.data.message);
    } finally {
      setLoadingJustDashboard(false);
    }
  }

  const { height, width } = getWindowDimensions();
  return (
    <AppLayout
      notifications={<Flashbar items={flashbarItems}/>}
      content={
        <div>
          <CommonHeader />
          <Tabs
            tabs={[
              {
                label: "Edit",
                id: "edit",
                content: <FeatureEditor triggerDashboardRefresh={triggerDashboardRefresh} metadata={featureMetadata} loading={loading} updateFeature={updateFeature} />
              },
              {
                label: "Dashboard",
                id: "dashboard",
                content: (
                  <SpaceBetween direction="vertical" size="s">
                    <Toggle
                      onChange={({ detail }) =>
                        setChecked(detail.checked)
                      }
                      checked={checked}
                    >
                      Show pipeline-specific changes
                    </Toggle>
                    <Dashboard pipelines={dashboardProps?.pipelines} loading={loading || loadingJustDashboard} overview={{ numCommits: featureMetadata?.commits?.length || 0, numPipelines: featureMetadata?.pipelines?.length || 0 }} triggerFeatureUpdate={triggerFeatureUpdate} />
                  </SpaceBetween>
  
                )
              },
            ]}
          />
        </div>
      }
      contentType="default"
      navigationHide={true}
      toolsHide={true}
      disableContentPaddings={true}
    />
  );
}