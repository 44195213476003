import React, { useCallback } from 'react';
import AppLayout from '@amzn/awsui-components-react/polaris/app-layout';
import Box from '@amzn/awsui-components-react/polaris/box';
import Button from '@amzn/awsui-components-react/polaris/button';
import TextFilter from '@amzn/awsui-components-react/polaris/text-filter';
import Header from '@amzn/awsui-components-react/polaris/header';
import Link from '@amzn/awsui-components-react/polaris/link';
import './styles.scss';
import Table from "@amzn/awsui-components-react/polaris/table";
import Api from '../api/FeatureTrackerApi';
import { Feature, FeatureInput } from '../api/generated-src';
import { Flashbar, FormField, Input, Modal, SpaceBetween, Spinner } from '@amzn/awsui-components-react/polaris';
import { useFlashbarItems, useSorting } from './hooks/hooks';
import CommonHeader from './CommonHeader';

const api = Api();

export default function Homepage() {
  const [ features, setFeatures ] = React.useState<Feature[]>([]);
  const [ loading, setLoading ] = React.useState(false);
  const [ loadingDelete, setLoadingDelete ] = React.useState(false);
  const [ selectedItems, setSelectedItems ] = React.useState<{ name: string; }[]>([]);
  const [ sortingColumn, isDescending, onSortingChange ] = useSorting(features, setFeatures);
  const { flashbarItems, addFlashbarItem, FLASHBAR_TYPES } = useFlashbarItems();
  const [ setIsFeatureModalVisible, setIsCreateFeatureModalVisible ] = React.useState(false);
  const [ isLoadingCreate, setIsLoadingCreate ] = React.useState(false);
  const [ newFeature, setNewFeature ] = React.useState<FeatureInput>({ name: '', bindleId: ''});
  const [ isBindleIDInvalid, setIsBindleIDInvalid ] = React.useState(false);
  const [ isNewFeatureNameInvalid, setIsNewFeatureNameInvalid ] = React.useState(false);


  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await api.listFeatures();
        setFeatures(response.data.features);
      } catch (error: any) {
        addErrorFlashbar(error.response.data.message);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const onClickDelete = async () => {
    const featureToRemove = selectedItems[0];
    try {
      setLoadingDelete(true);
      await api.deleteFeature(featureToRemove.name);
      setFeatures(features.filter(feature => !(feature.name === featureToRemove.name)));
      setSelectedItems([]);
    } catch (error: any) {
      addErrorFlashbar(error.response.data.message);
    } finally {
      setLoadingDelete(false);
    }
  };

  const addErrorFlashbar = (message: string) => {
    addFlashbarItem({ type: FLASHBAR_TYPES.ERROR, content: message })
  };

  const createFeature = async () => {
    let abortCreate;
    if (newFeature.name === undefined || newFeature?.name === '') {
      abortCreate = true;
      setIsNewFeatureNameInvalid(true);
    }

    if (newFeature?.bindleId === undefined || newFeature?.bindleId === '') {
      abortCreate = true;
      setIsBindleIDInvalid(true);
    }

    try {
      setIsLoadingCreate(true);
      await api.createFeature(newFeature);
      setFeatures([ ...features, newFeature ]);
      setSelectedItems([]);
    } catch (error: any) {
      addErrorFlashbar(error.response.data.message);
    } finally {
      setIsLoadingCreate(false);
      setIsCreateFeatureModalVisible(false);
      setNewFeature({ name: '', bindleId: ''})
    }
  };


  return (
    <AppLayout
      notifications={
        <Flashbar items={flashbarItems} />
      }
      content={
        <div>
          <CommonHeader />
          <Modal
            visible={setIsFeatureModalVisible}
            onDismiss={() => setIsCreateFeatureModalVisible(false)}
            header={'Create feature'}
            footer={
              <Box float="right">
                <Button variant='primary' loading={isLoadingCreate} disabled={!newFeature?.name || !newFeature?.bindleId} onClick={() => createFeature()}>Create</Button>
              </Box>
            }
          >
            <SpaceBetween size="s" direction="vertical">
              <FormField
                label='Feature name'
                description='Enter the name of your feature. Feature names must be unique across all of Amazon.'
              >
                <Input invalid={isNewFeatureNameInvalid} placeholder="Name" value={newFeature?.name || ""} onChange={(event) => { setIsNewFeatureNameInvalid(false); setNewFeature({ ...newFeature, name: event.detail.value || '' }) }}></Input>
              </FormField>
              <FormField
                label='Bindle ID'
                description='Enter the ID of the Bindle that will own this feature. Currently only gamma Bindles are supported (https://bindles-gamma.amazon.com). NOTE: Each entity that uses this feature (including yourself) must be allowlisted in the Bindles UI.'
              >
                <Input invalid={isBindleIDInvalid} placeholder="Bindle ID" value={newFeature?.bindleId || ""} onChange={(event) => { setIsBindleIDInvalid(false); setNewFeature({ ...newFeature, bindleId: event.detail.value }) }}></Input>
              </FormField>
            </SpaceBetween>
          </Modal>
          {loading && <Spinner/>}
          {!loading && <Table
            onSelectionChange={({ detail }) => {
              setSelectedItems(detail.selectedItems)
              console.log(detail.selectedItems);
            }
            }
            selectedItems={selectedItems}
            columnDefinitions={[
              {
                id: "feature",
                header: "Feature name",
                cell: e => <Link href={`#/features/${e.name}`}>{e.name}</Link>,
                sortingField: "name"
              }
            ]}
            items={features}
            loadingText="Loading resources"
            selectionType="single"
            trackBy="name"
            visibleColumns={[
              "feature"
            ]}
            empty={
              <Box textAlign="center" color="inherit">
                <b>No features</b>
                <Box
                  padding={{ bottom: "s" }}
                  variant="p"
                  color="inherit"
                >
                  No features to display.
                </Box>
              </Box>
            }
            filter={
              <TextFilter
                filteringPlaceholder="Find features"
                filteringText=""
              />
            }
            header={
              <Header 
                actions={
                  <SpaceBetween direction='horizontal' size='s'>
                    <Button disabled={selectedItems?.length > 0 ? false : true} loading={loadingDelete} onClick={() => onClickDelete()}>Delete</Button>
                    <Button disabled={selectedItems?.length > 0 ? false : true}>Edit</Button>
                    <Button variant='primary' onClick={() => setIsCreateFeatureModalVisible(true)}>Create feature</Button>
                  </SpaceBetween>
                }
              >
                Features
              </Header>
            }
            sortingColumn={sortingColumn}
            sortingDescending={isDescending}
            onSortingChange={onSortingChange}
          />}
        </div>}
      contentType="default"
      navigationHide={true}
      toolsHide={true}
      disableContentPaddings={true}
    />
  );
}