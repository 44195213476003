/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI Feature Tracker
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Commit
 */
export interface Commit {
    /**
     * 
     * @type {string}
     * @memberof Commit
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof Commit
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Commit
     */
    'package': string;
    /**
     * 
     * @type {Array<Target>}
     * @memberof Commit
     */
    'targets': Array<Target>;
    /**
     * 
     * @type {boolean}
     * @memberof Commit
     */
    'flagged': boolean;
}
/**
 * 
 * @export
 * @interface CommitMetadata
 */
export interface CommitMetadata {
    /**
     * 
     * @type {string}
     * @memberof CommitMetadata
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CommitMetadata
     */
    'package': string;
    /**
     * 
     * @type {string}
     * @memberof CommitMetadata
     */
    'type': string;
    /**
     * 
     * @type {boolean}
     * @memberof CommitMetadata
     */
    'flagged'?: boolean;
}
/**
 * 
 * @export
 * @interface Feature
 */
export interface Feature {
    /**
     * 
     * @type {string}
     * @memberof Feature
     */
    'name': string;
    /**
     * 
     * @type {Array<CommitMetadata>}
     * @memberof Feature
     */
    'commits'?: Array<CommitMetadata>;
    /**
     * 
     * @type {Array<PipelineMetadata>}
     * @memberof Feature
     */
    'pipelines'?: Array<PipelineMetadata>;
}
/**
 * 
 * @export
 * @interface FeatureInput
 */
export interface FeatureInput {
    /**
     * 
     * @type {string}
     * @memberof FeatureInput
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FeatureInput
     */
    'bindleId': string;
    /**
     * 
     * @type {Array<CommitMetadata>}
     * @memberof FeatureInput
     */
    'commits'?: Array<CommitMetadata>;
    /**
     * 
     * @type {Array<PipelineMetadata>}
     * @memberof FeatureInput
     */
    'pipelines'?: Array<PipelineMetadata>;
}
/**
 * 
 * @export
 * @interface FeatureStatus
 */
export interface FeatureStatus {
    /**
     * 
     * @type {Array<Pipeline>}
     * @memberof FeatureStatus
     */
    'pipelines': Array<Pipeline>;
}
/**
 * 
 * @export
 * @interface FeatureUpdate
 */
export interface FeatureUpdate {
    /**
     * 
     * @type {Array<CommitMetadata>}
     * @memberof FeatureUpdate
     */
    'commits'?: Array<CommitMetadata>;
    /**
     * 
     * @type {Array<PipelineMetadata>}
     * @memberof FeatureUpdate
     */
    'pipelines'?: Array<PipelineMetadata>;
}
/**
 * 
 * @export
 * @interface GitCommit
 */
export interface GitCommit {
    /**
     * 
     * @type {string}
     * @memberof GitCommit
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GitCommit
     */
    'message': string;
    /**
     * 
     * @type {GitCommitAuthor}
     * @memberof GitCommit
     */
    'author': GitCommitAuthor;
}
/**
 * 
 * @export
 * @interface GitCommitAuthor
 */
export interface GitCommitAuthor {
    /**
     * 
     * @type {string}
     * @memberof GitCommitAuthor
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GitCommitAuthor
     */
    'date'?: string;
}
/**
 * 
 * @export
 * @interface ListCommitsResponse
 */
export interface ListCommitsResponse {
    /**
     * 
     * @type {Array<GitCommit>}
     * @memberof ListCommitsResponse
     */
    'commits'?: Array<GitCommit>;
}
/**
 * 
 * @export
 * @interface ListFeaturesResponse
 */
export interface ListFeaturesResponse {
    /**
     * 
     * @type {Array<Feature>}
     * @memberof ListFeaturesResponse
     */
    'features': Array<Feature>;
    /**
     * 
     * @type {string}
     * @memberof ListFeaturesResponse
     */
    'nextToken'?: string;
}
/**
 * 
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * 
     * @type {number}
     * @memberof ModelError
     */
    'statusCode'?: number;
    /**
     * 
     * @type {object}
     * @memberof ModelError
     */
    'body'?: object;
}
/**
 * 
 * @export
 * @interface Pipeline
 */
export interface Pipeline {
    /**
     * 
     * @type {Array<Stage>}
     * @memberof Pipeline
     */
    'stages': Array<Stage>;
    /**
     * 
     * @type {string}
     * @memberof Pipeline
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface PipelineMetadata
 */
export interface PipelineMetadata {
    /**
     * 
     * @type {string}
     * @memberof PipelineMetadata
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface Stage
 */
export interface Stage {
    /**
     * 
     * @type {string}
     * @memberof Stage
     */
    'name': string;
    /**
     * 
     * @type {Array<Commit>}
     * @memberof Stage
     */
    'commits': Array<Commit>;
    /**
     * 
     * @type {string}
     * @memberof Stage
     */
    'pipeline': string;
    /**
     * 
     * @type {Array<Target>}
     * @memberof Stage
     */
    'targets': Array<Target>;
    /**
     * 
     * @type {number}
     * @memberof Stage
     */
    'number'?: number;
}
/**
 * 
 * @export
 * @interface Target
 */
export interface Target {
    /**
     * 
     * @type {string}
     * @memberof Target
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Target
     */
    'type': string;
}

/**
 * FeatureApi - axios parameter creator
 * @export
 */
export const FeatureApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create feature
         * @param {FeatureInput} [featureInput] The feature to create.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFeature: async (featureInput?: FeatureInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/feature`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(featureInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete feature
         * @param {string} featureName The name of the feature.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFeature: async (featureName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'featureName' is not null or undefined
            assertParamExists('deleteFeature', 'featureName', featureName)
            const localVarPath = `/feature/{featureName}`
                .replace(`{${"featureName"}}`, encodeURIComponent(String(featureName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get commits for a package.
         * @param {string} packageName The name of the package
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommits: async (packageName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageName' is not null or undefined
            assertParamExists('getCommits', 'packageName', packageName)
            const localVarPath = `/commits/{packageName}`
                .replace(`{${"packageName"}}`, encodeURIComponent(String(packageName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get feature
         * @param {string} featureName The name of the feature.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeature: async (featureName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'featureName' is not null or undefined
            assertParamExists('getFeature', 'featureName', featureName)
            const localVarPath = `/feature/{featureName}`
                .replace(`{${"featureName"}}`, encodeURIComponent(String(featureName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the status of the feature.
         * @param {string} featureName The name of the feature.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatureStatus: async (featureName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'featureName' is not null or undefined
            assertParamExists('getFeatureStatus', 'featureName', featureName)
            const localVarPath = `/feature/{featureName}/status`
                .replace(`{${"featureName"}}`, encodeURIComponent(String(featureName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List features
         * @param {number} [maxResults] The maximum number of results to return.
         * @param {string} [nextToken] The token to retrieve the next page of results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFeatures: async (maxResults?: number, nextToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/features`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (maxResults !== undefined) {
                localVarQueryParameter['maxResults'] = maxResults;
            }

            if (nextToken !== undefined) {
                localVarQueryParameter['nextToken'] = nextToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update feature
         * @param {string} featureName The name of the feature.
         * @param {FeatureUpdate} [featureUpdate] The feature data to update.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFeature: async (featureName: string, featureUpdate?: FeatureUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'featureName' is not null or undefined
            assertParamExists('updateFeature', 'featureName', featureName)
            const localVarPath = `/feature/{featureName}`
                .replace(`{${"featureName"}}`, encodeURIComponent(String(featureName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(featureUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FeatureApi - functional programming interface
 * @export
 */
export const FeatureApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FeatureApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create feature
         * @param {FeatureInput} [featureInput] The feature to create.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFeature(featureInput?: FeatureInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFeature(featureInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete feature
         * @param {string} featureName The name of the feature.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFeature(featureName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFeature(featureName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get commits for a package.
         * @param {string} packageName The name of the package
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCommits(packageName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCommitsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCommits(packageName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get feature
         * @param {string} featureName The name of the feature.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFeature(featureName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Feature>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFeature(featureName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the status of the feature.
         * @param {string} featureName The name of the feature.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFeatureStatus(featureName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeatureStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFeatureStatus(featureName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List features
         * @param {number} [maxResults] The maximum number of results to return.
         * @param {string} [nextToken] The token to retrieve the next page of results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listFeatures(maxResults?: number, nextToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListFeaturesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listFeatures(maxResults, nextToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update feature
         * @param {string} featureName The name of the feature.
         * @param {FeatureUpdate} [featureUpdate] The feature data to update.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFeature(featureName: string, featureUpdate?: FeatureUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFeature(featureName, featureUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FeatureApi - factory interface
 * @export
 */
export const FeatureApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FeatureApiFp(configuration)
    return {
        /**
         * 
         * @summary Create feature
         * @param {FeatureInput} [featureInput] The feature to create.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFeature(featureInput?: FeatureInput, options?: any): AxiosPromise<void> {
            return localVarFp.createFeature(featureInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete feature
         * @param {string} featureName The name of the feature.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFeature(featureName: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteFeature(featureName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get commits for a package.
         * @param {string} packageName The name of the package
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommits(packageName: string, options?: any): AxiosPromise<ListCommitsResponse> {
            return localVarFp.getCommits(packageName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get feature
         * @param {string} featureName The name of the feature.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeature(featureName: string, options?: any): AxiosPromise<Feature> {
            return localVarFp.getFeature(featureName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the status of the feature.
         * @param {string} featureName The name of the feature.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatureStatus(featureName: string, options?: any): AxiosPromise<FeatureStatus> {
            return localVarFp.getFeatureStatus(featureName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List features
         * @param {number} [maxResults] The maximum number of results to return.
         * @param {string} [nextToken] The token to retrieve the next page of results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFeatures(maxResults?: number, nextToken?: string, options?: any): AxiosPromise<ListFeaturesResponse> {
            return localVarFp.listFeatures(maxResults, nextToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update feature
         * @param {string} featureName The name of the feature.
         * @param {FeatureUpdate} [featureUpdate] The feature data to update.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFeature(featureName: string, featureUpdate?: FeatureUpdate, options?: any): AxiosPromise<void> {
            return localVarFp.updateFeature(featureName, featureUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FeatureApi - object-oriented interface
 * @export
 * @class FeatureApi
 * @extends {BaseAPI}
 */
export class FeatureApi extends BaseAPI {
    /**
     * 
     * @summary Create feature
     * @param {FeatureInput} [featureInput] The feature to create.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureApi
     */
    public createFeature(featureInput?: FeatureInput, options?: AxiosRequestConfig) {
        return FeatureApiFp(this.configuration).createFeature(featureInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete feature
     * @param {string} featureName The name of the feature.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureApi
     */
    public deleteFeature(featureName: string, options?: AxiosRequestConfig) {
        return FeatureApiFp(this.configuration).deleteFeature(featureName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get commits for a package.
     * @param {string} packageName The name of the package
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureApi
     */
    public getCommits(packageName: string, options?: AxiosRequestConfig) {
        return FeatureApiFp(this.configuration).getCommits(packageName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get feature
     * @param {string} featureName The name of the feature.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureApi
     */
    public getFeature(featureName: string, options?: AxiosRequestConfig) {
        return FeatureApiFp(this.configuration).getFeature(featureName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the status of the feature.
     * @param {string} featureName The name of the feature.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureApi
     */
    public getFeatureStatus(featureName: string, options?: AxiosRequestConfig) {
        return FeatureApiFp(this.configuration).getFeatureStatus(featureName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List features
     * @param {number} [maxResults] The maximum number of results to return.
     * @param {string} [nextToken] The token to retrieve the next page of results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureApi
     */
    public listFeatures(maxResults?: number, nextToken?: string, options?: AxiosRequestConfig) {
        return FeatureApiFp(this.configuration).listFeatures(maxResults, nextToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update feature
     * @param {string} featureName The name of the feature.
     * @param {FeatureUpdate} [featureUpdate] The feature data to update.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureApi
     */
    public updateFeature(featureName: string, featureUpdate?: FeatureUpdate, options?: AxiosRequestConfig) {
        return FeatureApiFp(this.configuration).updateFeature(featureName, featureUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}


