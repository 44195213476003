import React from 'react';
import { Box, Header, Link, Icon, Table, SpaceBetween, Pagination, SplitPanel } from '@amzn/awsui-components-react';
import { Pipeline } from './Dashboard';
import { useSorting } from './hooks/hooks';

interface EarliestCommitTableProps {
  items: any[] | undefined;
  columnDefinition: any[] | undefined;
  itemsMapping?: any | undefined;
  name: string | undefined;
}

export default (props: EarliestCommitTableProps) => {
  const [ currentPage, setCurrentPage ] = React.useState(1);
  const [ items, setItems ] = React.useState<any[]>([]);
  const [ sortingColumn, isDescending, onSortingChange ] = useSorting(items, setItems);
  
  const PAGE_SIZE = 10;
  
  React.useEffect(() => {
    if (props.itemsMapping) {
      console.log('flag1');
      setItems(props.itemsMapping(props.items || []));
    } else {
      setItems(props.items || []);
    }
  }, []);
  
  const columnDefinitions = props.columnDefinition || [];
  const name = props.name || '';
  
  return (
    <SplitPanel
      header={'View additional items'}
      hidePreferencesButton={true}
      i18nStrings={{
        // TODO
        closeButtonAriaLabel: 'string',
        openButtonAriaLabel: 'string',
        preferencesTitle: 'string',
        preferencesPositionLabel: 'string',
        preferencesPositionDescription: 'string',
        preferencesPositionSide: 'string',
        preferencesPositionBottom: 'string',
        preferencesConfirm: 'string',
        preferencesCancel: 'string',
        resizeHandleAriaLabel: 'string',
      }}
    >
      <Table
        resizableColumns={true}
        header={<Header counter={`(${items.length})`}>{`${name}`}</Header>}
        items={items.slice((currentPage - 1) * PAGE_SIZE, currentPage * PAGE_SIZE)}
        columnDefinitions={columnDefinitions}
        empty={
          <Box textAlign="center" color="inherit">
            <b>No resources</b>
            <Box padding={{ bottom: "s" }} variant="p" color="inherit">
              No resources to display.
            </Box>
          </Box>
        }
        pagination={
          <Pagination
            currentPageIndex={currentPage}
            pagesCount={Math.ceil(items.length / PAGE_SIZE)}
            onChange={({ detail }) => setCurrentPage(detail.currentPageIndex)}
          />
        }
        sortingColumn={sortingColumn}
        sortingDescending={isDescending}
        onSortingChange={(event) => onSortingChange(event)}
      />
    </SplitPanel>
  );
}