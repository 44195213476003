import { ReactElement, useCallback, useEffect, useState } from 'react';

import Icon from '@amzn/awsui-components-react/polaris/icon';
import { CommitItem } from './PipelineCommitTable';

interface FlaggedProps {
  item: FlaggedItem;
  callback: any;
}

interface FlaggedItem {
  flagged?: boolean;
}

const Flag = (props: FlaggedProps): ReactElement => {
  const [hovering, setHovering] = useState(false);

  const getVariant = () => {
    if (hovering) {
      return !props.item.flagged ? 'error' : 'normal';
    } 
    return props.item.flagged ? 'error' : 'normal';
  }

  const onClick = () => {
    props.item.flagged = !props.item.flagged;
    setHovering(false);
    props.callback(props.item);
  }

  return (
    <div>
      <span
        onClick={() => onClick()}
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
      >
        <Icon size="medium" name="flag" variant={getVariant()} />
      </span>
    </div>
  );
};

export { Flag };