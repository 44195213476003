import { Box, Button, FormField, Header, Input, Modal, Multiselect, MultiselectProps, NonCancelableCustomEvent, Pagination, Select, SelectProps, SpaceBetween, Table, Tabs, Textarea } from "@amzn/awsui-components-react";
import React from "react";
import { CommitMetadata, GitCommit } from "../api/generated-src";
import { useSorting } from "./hooks/hooks";
import { Flag } from "./Flag";
import { OptionDefinition } from "@amzn/awsui-components-react/polaris/internal/components/option/interfaces";
import FeatureTrackerApi from "../api/FeatureTrackerApi";

const api = FeatureTrackerApi();
interface CommitsTableProps {
  commitItems: CommitMetadata[];
  loading: boolean;
  setCommitItems: React.Dispatch<React.SetStateAction<CommitMetadata[]>>;
  updateFeature: any;
}

export default (props: CommitsTableProps) => {
  const [ isCommitIdInvalid, setIsCommitIdInvalid ] = React.useState(false);
  const [ isCommitPackageInvalid, setIsCommitPackageInvalid ] = React.useState(false);
  const [ newCommit, setNewCommit ] = React.useState<Partial<CommitMetadata>>();
  const [ currentPage, setCurrentPage ] = React.useState(1);
  const [ selectedItems, setSelectedItems ] = React.useState<CommitMetadata[]>([]);
  const [ isAddModalVisible, setIsAddModalVisible ] = React.useState<boolean>(false);
  const [ sortingColumn, isDescending, onSortingChange ] = useSorting(props.commitItems, props.setCommitItems);
  const [ isBulkTextInvalid, setIsBulkTextInvalid ] = React.useState(false);
  const [ text, setText ] = React.useState("");
  const [ selectedPackage, setSelectedPackage ] = React.useState<SelectProps.Option | null>(null);
  const [ selectedCommits, setSelectedCommits ] = React.useState<readonly OptionDefinition[]>([]);
  const [ selectedPackageCommits, setSelectedPackageCommits ] = React.useState<GitCommit[]>([]);
  const [ loading, setLoading] = React.useState(false);
  const [ error, setError ] = React.useState(false);
  const [ loadingUpdate, setLoadingUpdate ] = React.useState(false);

  const PAGE_SIZE = 10;

  const columnDefinitions = [
    {
      id: 'flagged',
      header: 'Flagged',
      cell: item => <Flag item={item} callback={async (commit: any) => { 
        const commitToUpdate = props.commitItems.find((tempCommit) => tempCommit.id === commit.id);
        if (!commitToUpdate) {
          return;
        }
        commitToUpdate.flagged = commit.flagged;
        return await props.updateFeature(props.commitItems, true);
      }}/>,
      width: 110,
      sortingField: 'flagged'
    },
    {
      id: "id",
      header: "Commit Id",
      cell: item => {
        return item.id;
      },
      sortingField: 'id'
    },
    {
      id: "package",
      header: "Package",
      cell: item => {
        return item.package;
      },
      sortingField: 'package'
    },
    {
      id: 'type',
      header: 'Commit type',
      cell: item => item.type === 'source' ? 'Source commit' : 'Testing commit',
      sortingField: 'type'
    },
  ];

  const getSelectObject = (type: string | undefined) => {
    if (type === 'source') {
      return { label: 'Source', value: 'source' }
    } else if (type === 'test') {
      return { label: 'Test', value: 'test' }
    }
  }

  const addCommit = async () => {
    const newCommitItems = [ ...props.commitItems ];
    let abortSingleCommit;
    if (newCommit?.id === undefined || newCommit?.id === "" || props.commitItems.map(item => item.id).includes(newCommit.id)) {
      setIsCommitIdInvalid(true);
      abortSingleCommit = true;
    }

    if (newCommit?.package === undefined || newCommit.package === "") {
      setIsCommitPackageInvalid(true);
      abortSingleCommit = true;
    }

    if (!abortSingleCommit) {
      if (!props.commitItems) return;
      newCommitItems.push({ id: newCommit?.id || "", package: newCommit?.package || "", type: newCommit?.type || "" });
    }
    
    if (text) {
      let abortBulkAdd = false;
      const commitsList: CommitMetadata[] = text.split('\n').map(commitText => {
        const [ packageName, id, type ] = commitText.split(',');
        if (!packageName || !id || !type || commitText.split(',').length > 3) {
          setIsBulkTextInvalid(true);
          abortBulkAdd = true;
        }
        return { id: id.trim(), package: packageName.trim(), type: type.trim() }
      });
      if (!abortBulkAdd) newCommitItems.push(...commitsList);
    }

    if (selectedCommits.length > 0) {
      const commitsList: CommitMetadata[] = selectedCommits.map(commit => ({ id: commit.value || '', package: selectedPackage?.label || '', type: 'source' }));
      newCommitItems.push(...commitsList);
    }
    
    setLoadingUpdate(true);
    const success = await props.updateFeature(newCommitItems, true);
    if (success) {
      props.setCommitItems(newCommitItems);
    }
    setLoadingUpdate(false);
    setNewCommit({});
    setIsCommitIdInvalid(false);
    setIsCommitIdInvalid(false);
    setText("");
    setSelectedCommits([]);
    setIsAddModalVisible(false);
  };
  
  const removeCommit = async () => {
    const newCommitItems = props.commitItems.filter(item => item.id != selectedItems[0].id);
    console.log(newCommitItems);
    const success = await props.updateFeature(newCommitItems, true);
    if (success) {
      props.setCommitItems(newCommitItems);
    }
    setSelectedItems([]);
  };

  const getCommitsForSelectedPackage = async (packageName: string) => {
    try {
      setLoading(true);
      const response = await api.getCommits(packageName);
      setSelectedPackageCommits(response.data.commits || []);
      setError(false);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const getStatusType = () => {
    if (loading) return 'loading';
    if (error) return 'error';
    return 'finished';
  }

  console.log('selectedCommits', selectedCommits);

  return (<Table
    columnDefinitions={columnDefinitions}
    selectedItems={selectedItems}
    selectionType="single"
    onSelectionChange={(event:any) => {setSelectedItems(event.detail.selectedItems)}}
    items={props.commitItems.slice((currentPage - 1) * PAGE_SIZE, currentPage * PAGE_SIZE)}
    loadingText="Loading resources"
    empty={
      <Box textAlign="center" color="inherit">
        <b>No resources</b>
        <Box
          padding={{ bottom: "s" }}
          variant="p"
          color="inherit"
        >
          No resources to display.
        </Box>
      </Box>
    }
    pagination={
      <Pagination
        currentPageIndex={currentPage}
        pagesCount={Math.ceil(props.commitItems.length / PAGE_SIZE)}
        onChange={({ detail }) => setCurrentPage(detail.currentPageIndex)}
      />
    }
    header={
    <Header description='These are the commits in the feature.' actions={
      <SpaceBetween size='xs' direction='horizontal'>
        <Button disabled={props.loading} onClick={() => setIsAddModalVisible(true)}>Add</Button>
        <Modal
          visible={isAddModalVisible}
          onDismiss={() => setIsAddModalVisible(false)}
          header={'Add commit'}
          footer={
            <Box float="right">
              <Button variant='primary' loading={loadingUpdate} disabled={!text && (!newCommit?.id || !newCommit) && selectedCommits.length === 0} onClick={() => addCommit()}>Add</Button>
            </Box>
          }
        >
            <Tabs
              tabs={[
                {
                  id: 'package-add',
                  label: 'Add by package',
                  content: (
                    <SpaceBetween size='s' direction="vertical">
                      <FormField
                        label='Package name'
                        description='Select that package that contains the commit that you intend to add. NOTE: This feature is only available to the Well-Architected Tool team.'
                      >
                        <Select 
                          selectedOption={selectedPackage} 
                          onChange={(event: NonCancelableCustomEvent<SelectProps.ChangeDetail>) => {setLoading(true); setSelectedPackageCommits([]); setSelectedCommits([]); setSelectedPackage(event.detail.selectedOption); getCommitsForSelectedPackage(event.detail.selectedOption.label || '')}}
                          options={[
                            { label: 'WellArchitectedExternalService', value: '1' },
                            { label: 'WellArchitectedWorkloadService', value: '2' },
                            { label: 'WellArchitectedApiServiceLambdaModel', value: '3' },
                            { label: 'WellArchitectedApiServiceLambda', value: '4' },
                            { label: 'WellArchitectedApiServiceLambdaTests', value: '5' },
                            { label: 'WellArchitectedConsoleWebsiteAssets', value: '6' },
                            { label: 'WellArchitectedConsoleWebsiteIntegTestsPuppeteer', value: '7' },
                            { label: 'WellArchitectedWorkloadCommons', value: '8' },
                            { label: 'WellArchitectedLambdaCommons', value: '9' },
                            { label: 'WellArchitectedJavaCommons', value: '10' },
                          ]}
                          placeholder="Choose a package"      
                          empty="No options"
                        />
                      </FormField>

                      <FormField
                        label='Commits'
                        description='Select commits to add to the feature. Commits that have already been added are disabled. This dropdown only includes the 20 most recent commits.'
                      >
                        <Multiselect
                          selectedOptions={selectedCommits}
                          onChange={(event: NonCancelableCustomEvent<MultiselectProps.MultiselectChangeDetail> ) => {setSelectedCommits(event.detail.selectedOptions);} }
                          options={selectedPackageCommits.map(commitFromGit => ({ label: commitFromGit.id.substring(0, 8), description: commitFromGit.message, iconName: 'share', value: commitFromGit.id, disabled: props.commitItems.map(commit => commit.id).includes(commitFromGit.id) }))}
                          loadingText='Loading commits'
                          statusType={getStatusType()} // loading / finished / error
                          disabled={!selectedPackage}
                          placeholder="Select commits"
                          />
                      </FormField>
                    </SpaceBetween>
                  )
                },
                {
                  id: 'standard-add',
                  label: 'Add by commit ID',
                  content: (
                    <SpaceBetween size="s" direction="vertical">
                      <FormField
                        label='Package name'
                        description='Enter the name of the package that contains the commit as it appears on code.amazon.com, without extra whitespace.'
                      >
                        <Input invalid={isCommitPackageInvalid} placeholder="Name" value={newCommit?.package || ""} onChange={(event) => { setIsCommitPackageInvalid(false); setNewCommit({ ...newCommit, package: event.detail.value }) }}></Input>
                      </FormField>
                      <FormField
                        label='Commit ID'
                        description='Enter the full commit ID (not just the appreviated version with eight characters).'
                      >
                        <Input invalid={isCommitIdInvalid} placeholder="ID" value={newCommit?.id || ""} onChange={(event) => { setIsCommitIdInvalid(false); setNewCommit({ ...newCommit, id: event.detail.value }) }}></Input>
                      </FormField>
                      <FormField
                        label='Commit type'
                        description='Commits can be separated into two categories: source and test commits.'
                      >
                        <Select
                          selectedOption={getSelectObject(newCommit?.type) || null}
                          options={[{ label: "Source", value: "source" }, { label: "Test", value: 'test' }]}
                          onChange={(event: any) => setNewCommit({ ...newCommit, type: event.detail.selectedOption.value })}
                          placeholder="Select commit type"
                        />
                      </FormField>
                    </SpaceBetween>)
                },
                {
                  id: 'add-bulk',
                  label: "Add bulk",
                  content: (
                  <FormField
                    label='Bulk add commits'
                    description='Enter a list of commits (separated by a newline) to add them to the feature. Each commit should be off the following form: <package-name>,<commit-d>,<type>.'
                  >
                    <Textarea
                      onChange={({ detail }) => setText(detail.value)}
                      value={text}
                      placeholder={
                        `DummyPackage,1234567890abcdefghijklmnopqrstuvwxyz,source`}
                      invalid={isBulkTextInvalid}
                    />
                  </FormField>
                  )
                }
              ]}
            />
        </Modal>
        <Button disabled={selectedItems.length === 0 || props.loading} onClick={() => removeCommit()}>Remove</Button>
      </SpaceBetween>
    }
      counter={`(${props.commitItems.length})`}
    >Commits</Header>
    }
    loading={props.loading}
    sortingColumn={sortingColumn}
    sortingDescending={isDescending}
    onSortingChange={(event) => onSortingChange(event)}
  />)
}