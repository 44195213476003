import { NonCancelableCustomEvent, TableProps } from "@amzn/awsui-components-react";
import React from "react"

export const useSorting = (items: any, setItems: any) => {
  const [ sortingColumn, setSortingColumn ] = React.useState<any>();
  const [ isDescending, setIsDescending ] = React.useState<boolean>();
  
  const onSortingChange = (event: NonCancelableCustomEvent<TableProps.SortingState<any>>) => {
    console.log(event.detail.isDescending);
    const firstSortValue = event.detail.isDescending ? 1 : -1;
    const secondSortValue = event.detail.isDescending ? -1 : 1;

    const sortedItems = items.sort((a, b) => {
      const sortingField = event.detail.sortingColumn.sortingField || '';
      console.log(a[sortingField], b[sortingField]);
      if (typeof a[sortingField] === "string") {
        if (a[sortingField] > b[sortingField]) {
          return firstSortValue;
        } else if (a[sortingField] < b[sortingField]) {
          return secondSortValue;
        }
        return 0;
      }

      if (typeof a[sortingField] === "boolean") {
        if (a[sortingField] && !b[sortingField]) {
          return firstSortValue;
        } else if (!a[sortingField] && b[sortingField]) {
          return secondSortValue;
        }
        return 0;
      }
    });
    console.log(sortedItems);
    setItems(sortedItems);
    setSortingColumn(event.detail.sortingColumn);
    setIsDescending(event.detail.isDescending);
  };
  return [ sortingColumn, isDescending, onSortingChange ];
}

export const useFlashbarItems = () => {
  const nextItemIdRef = React.useRef(1);
  const flashbarTypes = React.useRef({
    SUCCESS: 'success',
    ERROR: 'error',
    WARNING: 'warning',
    INFO: 'info',
  });
  const [items, setItems] = React.useState<any[]>([]);

  return {
    flashbarItems: items,
    FLASHBAR_TYPES: flashbarTypes.current,
    addFlashbarItem: ({ type, dismissible = true, header = undefined, content }) => {
      setItems((items) => {
        const itemIdString = `item-${nextItemIdRef.current}`;
        return items.concat({
          type,
          dismissible: dismissible ?? true,
          header,
          content,
          dismissLabel: 'Dismiss',
          id: itemIdString,
          onDismiss: () => setItems((_items) => _items.filter(({ id }) => id !== itemIdString)),
        });
      });

      nextItemIdRef.current += 1;
    },
  };
};